import React from "react";
var moment = require("moment");

var pervious_prograss_status = "";
const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
export default class Print extends React.PureComponent {
    render() {
        const { id } = this.props.requestData;

      

        return (
            <div style={{ margin: 40 }}>
                <div className="row">
                    <div style={{ marginTop: 36, marginLeft: 20 }}>
                        <img
                            style={{ width: 300 }}
                            src={require("../../../../img/navLogoH.png")}
                        />
                    </div>
                    <div
                        className="box-header"
                        style={{
                            display: "grid",
                        }}
                    >
                        <h2
                            style={{ marginLeft: "260px", marginTop: "150px" }}
                            className="box-title "
                        >
                            Shangri-la Investments Lanka (Pvt) Ltd
            </h2>
                    </div>
                </div>
                <div className="box">
                    <div className="box-body">
                        <h4 style={{ marginBottom: 25, marginTop: 45 }}>
                            {" "}
                            <b>System Access Request - Form Number</b> :{" "}
                            {this.props.requestData.formNumber !== null &&
                                this.props.requestData.formNumber}{" "}
                        </h4>

                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>Request By</th>
                                    <th>Department Name</th>
                                    <th>Location</th>
                                    <th>Created Date</th>
                                </tr>
                                <tr>
                                    <td>
                                        {this.props.requestData.createdUser.firstName +
                                            " " +
                                            this.props.requestData.createdUser.lastName}
                                    </td>
                                    <td>
                                        {this.props.requestData.createdUser.department !== null &&
                                            this.props.requestData.createdUser.department.name}{" "}
                                    </td>
                                    <td>Shangri-la Investments Lanka (Pvt) Ltd</td>
                                    <td> {this.props.requestData.createdDate.split("T")[0]} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="box">
                    <div
                        className="box-header"
                        style={{ marginTop: 40, marginBottom: 20 }}
                    ></div>
                    <h5 style={{ marginTop: 30 }}>Employee Details</h5>
                    <div className="box-body" style={{ marginTop: 30 }}>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th style={{ width: 340 }}>Attribute Name</th>
                                    <th> Values</th>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}>Requested Date</td>
                                    <td>
                                        {" "}
                                        {moment(
                                            new Date(this.props.requestData.dateRequested)
                                        ).format("YYYY-MM-DD")}
                                    </td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}>New Colleague's Emp. No.</td>
                                    <td> {this.props.requestData.employeeNumber}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}>First Name</td>
                                    <td> {this.props.requestData.firstName}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}>Last Name</td>
                                    <td> {this.props.requestData.lastName}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}> Employement Level </td>
                                    <td>
                                        {this.props.requestData.employmentLevel !== null &&
                                            this.props.requestData.employmentLevel.name +
                                            " - " +
                                            this.props.requestData.employmentLevel.description}
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ width: 340 }}>Job Title</td>
                                    <td> {this.props.requestData.jobTitle}</td>
                                </tr>

                                <tr>
                                    <td style={{ width: 340 }}> Department </td>
                                    <td>
                                        {this.props.requestData.department !== null &&
                                            this.props.requestData.department.name}
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        {this.props.requestData.systemAccessRequestSystems.length > 0 && (
                            <div style={{ marginRight: 500 }}>
                                <h5 style={{ marginTop: 50 }}>System Access Required</h5>

                                {this.props.requestData.systemsSubSystems.length > 0 && (
                                    <>
                                        {this.props.requestData.status !== null &&
                                            this.props.requestData.status.name == "Completed" ? (
                                                <table
                                                    className="table table-bordered"
                                                    style={{ marginTop: 30 }}
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th>System</th>
                                                            <th>Username</th>
                                                        </tr>
                                                    </thead>

                                                    {this.props.requestData.systemsSubSystems.map(
                                                        (system, index) => (
                                                            <tbody key={index}>
                                                                <tr>
                                                                    <td style={{ width: "340px" }}>
                                                                        <div>{system.name}</div>
                                                                    </td>
                                                                    <td style={{ width: "340px" }}>
                                                                        <div>{system.username}</div>
                                                                    </td>
                                                                </tr>
                                                                {system.subSystemAccess !== null &&
                                                                    system.subSystemAccess.map(
                                                                        (subSystem, sindex) => (
                                                                            <tr key={sindex}>
                                                                                <td style={{ width: "340px" }}>
                                                                                    <div className="ml-3">
                                                                                        {subSystem.name}
                                                                                    </div>
                                                                                </td>
                                                                                <td style={{ width: "340px" }}>
                                                                                    <div>{subSystem.username}</div>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    )}
                                                            </tbody>
                                                        )
                                                    )}
                                                </table>
                                            ) : (
                                                <table
                                                    className="table table-bordered"
                                                    style={{ marginTop: 30 }}
                                                >
                                                    {this.props.requestData.systemsSubSystems.map(
                                                        (system, index) => {
                                                            return (
                                                                <tbody key={index}>
                                                                    <tr>
                                                                        <td style={{ width: "340px" }}>
                                                                            <div>{system.name}</div>
                                                                        </td>
                                                                        <td> Yes </td>
                                                                    </tr>
                                                                </tbody>
                                                            );
                                                        }
                                                    )}
                                                </table>
                                            )}
                                    </>
                                )}
                            </div>
                        )}

                        {this.props.requestData.requestProgress.length > 0 && (
                            <div style={{ pageBreakBefore: "none" }}>
                                <div className="box">
                                    <div
                                        className="box-header"
                                        style={{ marginTop: 40, marginBottom: 20 }}
                                    >
                                        <h5>Document Approval History</h5>
                                    </div>
                                    <div className="box-body">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>User</th>
                                                    <th>Stage</th>
                                                    <th>Action</th>
                                                </tr>
                                                {this.props.requestData.requestProgress.length > 0 &&
                                                    this.props.requestData.requestProgress.map(
                                                        (progress, index) => {
                                                            if (index == 0) {
                                                                pervious_prograss_status = "";
                                                            }

                                                            if (pervious_prograss_status != "Pending") {
                                                                pervious_prograss_status = progress.tookAction;
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{progress.date}</td>
                                                                        <td>{progress.userName}</td>
                                                                        <td>{progress.levelName}</td>
                                                                        <td>{progress.tookAction}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        }
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {JSON.parse(this.props.requestData.comments).length > 0 && (
                            <div style={{ pageBreakBefore: "none" }}>
                                <h5 style={{ marginTop: 50 }}>Comments</h5>

                                <table
                                    className="table table-bordered"
                                    style={{ marginTop: 30 }}
                                >
                                    <tbody>
                                        <tr>
                                            <th style={{ width: 340 }}>Level Name</th>
                                            <th> Comment </th>
                                            <th> Date </th>
                                        </tr>
                                        {JSON.parse(this.props.requestData.comments).length > 0 &&
                                            JSON.parse(this.props.requestData.comments).map(
                                                (comment) => {
                                                    return (
                                                        <tr>
                                                            <td style={{ width: 340 }}>{comment.name}</td>
                                                            <td> {comment.comment}</td>
                                                            <td> {comment.dateTime}</td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>

                <br />

                <span className="">
                    Printed On : {moment(new Date()).format("YYYY-MM-DD")}
                    <span className=" pull-right">
                        Generated By : {this.props.firstName + " " + this.props.lastName}
                    </span>
                </span>
            </div>
        );
    }
}

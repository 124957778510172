import React from "react";
var moment = require("moment");

var pervious_prograss_status = "";
const pageStyle = `
  @page {
    size: 80mm 50mm;
  }

  @media all {
    .pagebreak {
      display: none;
    }
  }

  @media print {
    .pagebreak {
      page-break-before: always;
    }
  }
`;
export default class Print extends React.PureComponent {
    render() {
        const { id } = this.props.requestData;

        

        return (
            <div style={{ margin: 40 }}>
                <div className="row">
                    <div style={{ marginTop: 36, marginLeft: 20 }}>
                        <img
                            style={{ width: 300 }}
                            src={require("../../../../img/navLogoH.png")}
                        />
                    </div>
                    <div
                        className="box-header"
                        style={{
                            display: "grid",
                        }}
                    >
                        <h2
                            style={{ marginLeft: "260px", marginTop: "150px" }}
                            className="box-title "
                        >
                            Shangri-la Investments Lanka (Pvt) Ltd
                        </h2>
                    </div>
                </div>
                <div className="box">
                    <div className="box-body">
                        <h4 style={{ marginBottom: 25, marginTop: 45 }}>
                            {" "}
                            <b>POS Item Request - Form Number</b> :{" "}
                            {this.props.requestData.formNumber !== null &&
                                this.props.requestData.formNumber}{" "}
                        </h4>

                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>Request By</th>
                                    <th>Department Name</th>
                                    <th>Location</th>
                                    <th>Created Date</th>
                                </tr>
                                <tr>
                                    <td>
                                        {this.props.requestData.createdUser.firstName +
                                            " " +
                                            this.props.requestData.createdUser.lastName}
                                    </td>
                                    <td>
                                        {this.props.requestData.createdUser.department !== null &&
                                            this.props.requestData.createdUser.department.name}{" "}
                                    </td>
                                    <td>Shangri-la Investments Lanka (Pvt) Ltd</td>
                                    <td> {this.props.requestData.createdOn.split("T")[0]} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="box">
                    <div
                        className="box-header"
                        style={{ marginTop: 40, marginBottom: 20 }}
                    ></div>
                    <h5 style={{ marginTop: 30 }}>General</h5>
                    <div className="box-body" style={{ marginTop: 30 }}>
                        <div style={{ marginRight: 400 }}>
                            {this.props.requestData.posItemRequestOutlet.length > 0 && (
                                <table className="table table-bordered">
                                    <tr>
                                        <th style={{ width: 200 }}>Attribute</th>
                                        <th>Value</th>
                                    </tr>
                                    <tr>
                                        <td
                                            style={{ width: 200, verticalAlign: "middle"}}
                                            rowspan={
                                                this.props.requestData.posItemRequestOutlet.length + 1
                                            }
                                        >
                                            Outlet Names
                    </td>
                                    </tr>
                                    {this.props.requestData.posItemRequestOutlet.map(
                                        (outlet, index) => (
                                            <tr key={index}>
                                                <td>{outlet.outletName}</td>
                                            </tr>
                                        )
                                    )}
                                    <tr>
                                        <td style={{ width: 200 }}>Date Required</td>
                                        <td>
                                            {moment(
                                                new Date(this.props.requestData.dateRequired)
                                            ).format("YYYY-MM-DD")}
                                        </td>
                                    </tr>
                                </table>
                            )}
                        </div>
                        <h5 style={{ marginTop: 30 }}>POS Items</h5>

                        <div style={{ marginTop: 30 }}>
                            {this.props.requestData.posItems.length > 0 && (
                                <table className="table table-bordered">
                                    <tr>
                                        <th>Menu Options</th>
                                        <th>Description</th>
                                        <th>PLU Number</th>
                                        <th>Category</th>
                                        <th>New Price</th>
                                        <th>Old Price</th>
                                        <th>Unit Cost</th>
                                        <th>Cost %</th>
                                        <th colspan="5" style={{ textAlign: "center" }}>
                                            Printers
                    </th>
                                        <th>Price Updates</th>
                                    </tr>

                                    {this.props.requestData.posItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>
                                                {item.menuOption !== null ? item.menuOption.code : ""}
                                            </td>
                                            <td>{item.description}</td>
                                            <td>{item.pluNumber}</td>
                                            <td>
                                                {item.category !== null ? item.category.name : ""}
                                            </td>
                                            <td>{item.newPrice}</td>
                                            <td>{item.oldPrice}</td>
                                            <td>{item.unitCost}</td>
                                            <td>{item.cost}</td>

                                            {item.posPrinters.map((printer, index) => (
                                                <td key={index}>{printer.name}</td>
                                            ))}
                                            {Array.from(
                                                {
                                                    length:
                                                        5 -
                                                        this.props.requestData.posItems[index].posPrinters
                                                            .length,
                                                },
                                                (_, i) => i + 1
                                            ).map((i) => (
                                                <td align="center" key={i}></td>
                                            ))}
                                            <td>
                                                {item.priceUpdate !== null ? item.priceUpdate.name : ""}
                                            </td>
                                        </tr>
                                    ))}
                                </table>
                            )}
                        </div>

                        {this.props.requestData.requestProgress.length > 0 && (
                            <div style={{ pageBreakBefore: "none" }}>
                                <div className="box">
                                    <div
                                        className="box-header"
                                        style={{ marginTop: 40, marginBottom: 20 }}
                                    >
                                        <h5>Document Approval History :</h5>
                                    </div>
                                    <div className="box-body">
                                        <table className="table table-bordered">
                                            <tbody>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>User</th>
                                                    <th>Stage</th>
                                                    <th>Action</th>
                                                </tr>
                                                {this.props.requestData.requestProgress.length > 0 &&
                                                    this.props.requestData.requestProgress.map(
                                                        (progress, index) => {
                                                            if (index == 0) {
                                                                pervious_prograss_status = "";
                                                            }

                                                            if (pervious_prograss_status != "Pending") {
                                                                pervious_prograss_status = progress.tookAction;
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{progress.date}</td>
                                                                        <td>{progress.userName}</td>
                                                                        <td>{progress.levelName}</td>
                                                                        <td>{progress.tookAction}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        }
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        )}

                        {JSON.parse(this.props.requestData.comments).length > 0 && (
                            <div style={{ pageBreakBefore: "none" }}>
                                <h5 style={{ marginTop: 50 }}>Comments</h5>

                                <table
                                    className="table table-bordered"
                                    style={{ marginTop: 30 }}
                                >
                                    <tbody>
                                        <tr>
                                            <th style={{ width: 340 }}>Level Name</th>
                                            <th> Comment </th>
                                            <th> Date </th>
                                        </tr>
                                        {JSON.parse(this.props.requestData.comments).length > 0 &&
                                            JSON.parse(this.props.requestData.comments).map(
                                                (comment) => {
                                                    return (
                                                        <tr>
                                                            <td style={{ width: 340 }}>{comment.name}</td>
                                                            <td> {comment.comment}</td>
                                                            <td> {comment.dateTime}</td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>

                <br />

                <span className="">
                    Printed On : {moment(new Date()).format("YYYY-MM-DD")}
                    <span className=" pull-right">
                        Generated By : {this.props.firstName + " " + this.props.lastName}
                    </span>
                </span>
            </div>
        );
    }
}

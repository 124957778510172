import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import { setAlert } from "./alert";
import {
    GET_ALL_SYSTEM_ACCESSES,
    GET_ALL_EMP_LEVEL,
    GET_ALL_EMP_DEPARTMENTS,
    GET_SYSTEM_ACCESS_REQUEST_BY_ID,
} from './types';

export const getSystemAccessData = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const config = {
        'Content-Type': 'application/json',
    };
    try {
        const res = await axios.get(`/api/SystemsAccess/get-all`, config);

        dispatch({
            type: GET_ALL_SYSTEM_ACCESSES,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

export const getEmpLevelData = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const config = {
        'Content-Type': 'application/json',
    };
    try {
        const res = await axios.get(`/api/EmployementLevel/get-all`, config);

     

        dispatch({
            type: GET_ALL_EMP_LEVEL,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

export const getEmpDepartmentData = () => async dispatch => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }
    const config = {
        'Content-Type': 'application/json',
    };
    try {
        const res = await axios.get(`/api/departments/get-all`, config);

      

        dispatch({
            type: GET_ALL_EMP_DEPARTMENTS,
            payload: res.data,
        });
    } catch (err) {
        dispatch(setAlert('Something Went Wrong', 'error'));
    }
};

export const submitAccessRequest = (formData, history) => async (dispatch) => {

    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            'Content-Type': 'application/json',
        };

        const res = await axios.post(
            `/api/SystemAccessRequest/submit`,
            formData,
            config
        );

        if (res.status == 200) {
            history.push(`/user-dashboard`);
            dispatch(setAlert('Request has been recorded', 'success'));
        }
    } catch (err) { }
};

export const getSystemAccessRequestById = (Id, accesslevelId) => async (dispatch) => {
    if (localStorage.token) {
        setAuthToken(localStorage.token);
    }

    try {
        const config = {
            "Content-Type": "application/json",
        }; 
        
        const res = await axios.get(
            `/api/SystemAccessRequest/get-request?Id=${Id}&accessLevelId=${accesslevelId}`,
            config
        );

        dispatch({ type: GET_SYSTEM_ACCESS_REQUEST_BY_ID, payload: res.data });

    } catch (err) {
        console.log(err);
    }
};

export const systemAccessRequestButtonClick =
    (systemsSubSystems, formData, additionalComment, access, userId, history) =>
        async (dispatch) => {
           

            if (localStorage.token) {
                setAuthToken(localStorage.token);
            }

            try {
                const config = {
                    'Content-Type': 'application/json',
                };

                const stringyfyAdditionalComment = JSON.stringify(additionalComment);

                const body = {
                    requestProcessLevelActionId: formData.requestProcessLevelActionId,
                    requestId: formData.requestId,
                    requestLevelId: formData.requestLevelId,
                    actionId: formData.actionId,
                    additionalComment: stringyfyAdditionalComment,
                    SystemsDTO: systemsSubSystems,
                    AttachmentDataDTO: formData.Attachments,
                };

             

                const res = await axios.post(
                    '/api/SystemAccessRequest/submit-action',
                    body,
                    config
                );
                if (res.status == 200) {
                    history.push(`/user-dashboard`);
                    dispatch(
                        setAlert(
                            `Form ${formData.formNumber} Has Successfully Recorded`,
                            'success'
                        )
                    );
                }

            } catch (err) {
                dispatch(setAlert('Something Went Wrong', 'error'));
            }
        };

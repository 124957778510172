import React, { useEffect, useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { getRequestById, requestButtonClick } from "../../actions/requests";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import Sidebar from "../sidebar/Sidebar";
import Spinner from "../layout/Spinner";
import Status from "../Shared/Status";
import UserInfo from "../user/UserInfo";
import AdditionalComment from "../Card/AdditionalComment";
import ReactToPrint from "react-to-print";
import RequestItemComponent from "./RequestItemComponent";
import FormPrint from "../print/FormPrintComponent";
import Comment from "../Card/Comment";
import PrintIcon from "@material-ui/icons/Print";

const RequestItem = ({
  getRequestById,
  history,
  requestButtonClick,
  requests: { request, request_loading },
  auth: { isAuthenticated, access, id, firstName, lastName },
  match,
}) => {

  useEffect(() => {
    getRequestById(match.params.id);
  }, [match.params.id, getRequestById]);

  //Additonal comment component popup
  const [open, setOpen] = useState(false);

  // Button Name State
  const [buttonName, setButtonName] = useState("");

    // Form Data for submit additonal comments
    const [formData, setFormData] = useState({
        requestProcessLevelActionId: "",
        requestId: "",
        requestLevelId: "",
        actionId: "",
        Attachments: [],
    });

  // Additional comment state
  const [addtionalComment, setAdditionalComment] = useState({
    name: "",
    comment: "",
  });

// On Click action button loading
const [actionButtonClickLoading, setActionButtonClickLoading] =
        useState(false);

  // Comment parse to json
  let jsonParseComment;

  // Check if comment available or not
  if (!request_loading && request !== null) {
    if (request.comments !== null) {
      jsonParseComment = JSON.parse(request.comments);
    }
    }

    // @Method onClick to handle button click
    const onClick = async (processLevel, requestLevel, requestId, actionId) => {
        setFormData({
            ...formData,
            requestProcessLevelActionId: processLevel,
            requestId: requestId,
            actionId: actionId,
            requestLevelId: requestLevel,
        });
        // await setAccessLevel(access)
    };

  const componentRef = useRef(null);

  // Additional comment button click submit handler
  const onButtonClick = async (e) => {
      e.preventDefault();
      setActionButtonClickLoading(true);
    setOpen(!open);
    if (addtionalComment.comment !== "") {
      await jsonParseComment.push(addtionalComment);
    }
    await requestButtonClick(formData, jsonParseComment, access, id, history);
  };

  // On change for additional comment
  const onChange = (e) => {
    setAdditionalComment({
      ...addtionalComment,
      name: firstName,
      [e.target.name]: e.target.value,
    });
  };

  const { comment } = addtionalComment;

  /**
   *
   * Additional comment popup prop
   *
   */
  const onAdditionalCommentPopup = () => {
    setOpen(!open);
    };

    const [fileNameArray, setFileNameArray] = useState([]);

    // File List callback
    const fileListCallback = useCallback((fileNameList) => {
        setFileNameArray(fileNameList);
    }, []);


  return (
    <div>
          {!request_loading && request !== null && !actionButtonClickLoading ? (
        <>
                  <form onSubmit={(e) => onButtonClick(e)}>
                      <AdditionalComment
                          openState={open}
                          onClick={onAdditionalCommentPopup}
                          button={buttonName}
                          value={comment}
                          name="comment"
                          onChange={onChange}
                          attachmnetDisplay
                          setState={setFormData}
                          state={formData}
                          currentAttachments={{
                              data: fileNameArray,
                              property: "fileName",
                          }}
                      />
                  </form>

          <div
            className="card w-100 sticky-top mb-3 detail-sticky"
            style={{
              maxHeight: "140px",
              zIndex: "1030",
              paddingTop: "1em",
            }}
          >
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
            <a href="/user-dashboard" style={{  textDecoration: "none",
              display: "inline-block",
              padding: "8px 8px 0px 8px", backgroundColor: "#12343B",
              color: "white",  borderRadius: "10%",  height: "40px", lineHeight: "24px", display: "flex", alignItems: "center" }}>Back</a>
            <h5
              className="ml-3"
              style={{
                color: "#333333",
                fontWeight: "bold",
                fontFamily: "Montserrat",
                marginTop: "10px"
              }}
            >
              {request.formNumber !== null && request.formNumber}{" "}
              {request.requestType.name}
            </h5>
            </div>

            <div className="horizontal-container mt-1">
              <Status progress={request.requestProgress} />
            </div>
          </div>
          <main>
            <div
              className="container-fluid"
              style={{
                marginTop: "20px",
              }}
            >
              <UserInfo
                firstName={request.createdUser.firstName}
                lastName={request.createdUser.lastName}
                email={request.createdUser.email}
                department={request.createdUser.department}
              />

               <RequestItemComponent request={request} setFileNameList={fileListCallback} />
              <Comment
                accessLevel={access}
                comments={request.comments}
                userId={id}
                requestId={request.id}
                userName={firstName + " " + lastName}
                statusId={request.statusId}
              />
              <div style={{ display: "none" }}>
                <FormPrint
                  ref={componentRef}
                  requestData={request}
                  name={firstName + " " + lastName}
                />
              </div>

              <div>
                <ReactToPrint
                  trigger={() => {
                    // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                    // to the root node of the returned component as it will be overwritten.
                    return (
                      <button className="btn btn-outline-info ml-3 mb-3">
                        <PrintIcon /> <b> Print This Out</b>
                      </button>
                    );
                  }}
                  content={() => componentRef.current}
                />
              </div>
              <div style={{ textAlign: "right" }} className="mr-3">
                {request.actions !== null
                  ? request.actions.map((action, index) => (
                      <button
                        key={index}
                        style={{ width: "200px" }}
                        className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                        onClick={() => {
                          onAdditionalCommentPopup();
                          setButtonName(action.name);
                          onClick(
                            action.requestProcessLevelActionId,
                            action.requestLevelId,
                              action.requestId,
                              action.actionId
                          );
                        }}
                      >
                        <b>{action.name}</b>
                      </button>
                    ))
                  : ""}
              </div>
            </div>
          </main>
        </>
      ) : (
        <div className="container-auth">
          <Spinner />
        </div>
      )}
    </div>
  );
};

RequestItem.propTypes = {
  auth: PropTypes.object.isRequired,
  requests: PropTypes.object.isRequired,
  getRequestById: PropTypes.func.isRequired,
  requestButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  requests: state.requests,
});

export default connect(mapStateToProps, {
  getRequestById,
  requestButtonClick,
})(withRouter(RequestItem));

import "./index.css";
import {
  getSystemAccessRequestById,
  systemAccessRequestButtonClick,
} from "../../../actions/systemAccess";
import NumberFormat from "react-number-format";
import PrintIcon from "@material-ui/icons/Print";
import ReactToPrint from "react-to-print";
import Print from "./Print";
import NoAttachment from "../../../img/no_attachment.svg";
import Status from "../../Shared/Status";
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import UserInfo from "../../user/UserInfo";
import CardSpinner from "../../layout/CardSpinner";
import AdditionalComment from "../../Card/AdditionalComment";
import CommentStaticForms from "../../Card/CommentStaticForms";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
var moment = require("moment");

const SystemAccessRequestDetail = ({
  match,
  history,
  getSystemAccessRequestById,
  systemAccessRequestButtonClick,
  systemAccess: { system_access_request_item, request_item_loading },
}) => {

    // Get App User Access Levels
    const formAccessLevels = useSelector((state) => state.app.formAccessLevels);

    useEffect(() => {
        if (formAccessLevels !== null) {
            getSystemAccessRequestById(match.params.id, formAccessLevels["System Access Request"]);
        }
   
    }, [match.params.id, formAccessLevels]);

  //set maximum value sub system number field
  const MAX_VAL = 9999;
  const withValueCap = (inputObj) => {
    const { value } = inputObj;
    if (value <= MAX_VAL) return true;
    return false;
  };

  // Get auth state from using use Selector
  const auth = useSelector((state) => state.auth);

  // Comment parse to json
  let jsonParseComment;

  const [form, setForm] = useState(null);

  useEffect(() => {
    if (system_access_request_item !== null) {
      setForm(system_access_request_item);
    }
  }, [system_access_request_item]);

  // Check if comment available or not
  if (!request_item_loading) {
    if (system_access_request_item.comments !== null) {
      jsonParseComment = JSON.parse(system_access_request_item.comments);
    }
  }

  // Additional comment state
  const [addtionalComment, setAdditionalComment] = useState({
    name: "",
    comment: "",
    dateTime: "",
  });

  const { comment } = addtionalComment;

  // Form Data for submit additonal comments
  const [formData, setFormData] = useState({
    formNumber: "",
    requestProcessLevelActionId: "",
    requestId: "",
    requestLevelId: "",
    actionId: "",
    systemAccessRequestSystems: [],
    Attachments: [],
  });

  //Additonal comment component popup
  const [open, setOpen] = useState(false);

  // Action button click loading spinner
  const [actionButtonLoading, setActionButtonLoading] = useState(false);
  // Button Name State
  const [buttonName, setButtonName] = useState("");

  // On change for additional comment
  const onChange = (e) => {
    setAdditionalComment({
      ...addtionalComment,
      name: auth.firstName + " " + auth.lastName,
      [e.target.name]: e.target.value,
      dateTime: moment().format("YYYY-MM-DD" + " " + "HH:mm"),
    });
  };

  let values = form;
  // On change for parent system username
  const onChangeSystem = (index, e) => {
    values.systemsSubSystems[index].username = e.target.value;
    setForm({ ...form, values });
  };

  // On change for sub system username
  const onChangeSubSystem = (index, sindex, e) => {
    values.systemsSubSystems[index].subSystemAccess[sindex].username =
      e.target.value;
    setForm({ ...form, values });
  };

  // @Method onClick to handle button click
  const onClick = async (processLevel, requestLevel, requestId, actionId) => {
    setFormData({
      ...formData,
      formNumber: system_access_request_item.id,
      requestProcessLevelActionId: processLevel,
      requestId: requestId,
      actionId: actionId,
      requestLevelId: requestLevel,
    });
    // await setAccessLevel(access)
  };

  // Additional comment button click submit handler
  const onButtonClick = async (e) => {
    e.preventDefault();
    setOpen(!open);
    setActionButtonLoading(true);
    if (addtionalComment.comment !== "") {
      await jsonParseComment.push(addtionalComment);
    }
    await systemAccessRequestButtonClick(
      form.systemsSubSystems,
      formData,
      jsonParseComment,
      auth.access,
      auth.id,
      history
    );
  };

  // Additional comment popup prop
  const onAdditionalCommentPopup = (e) => {
    e.preventDefault();
    setOpen(!open);
  };

  // Print componet ref
  const componentRef = useRef(null);

  return (
    <>
      <div
        className="card w-100 sticky-top mb-3 detail-sticky"
        style={{
          maxHeight: "100%",
          zIndex: "1030",
        }}
      >
        {!request_item_loading && !actionButtonLoading ? (
          <>
            <div className="row p-0 m-0">
              <div className="col-lg-9">
              <div style={{ display: "flex", flexDirection: "row", gap: "5px", alignItems: "center" }}>
              <a href="/user-dashboard" style={{  textDecoration: "none",
              display: "inline-block",
              padding: "8px 8px 8px 8px", backgroundColor: "#12343B",
              color: "white",  borderRadius: "10%", height: "40px", lineHeight: "24px", display: "flex", alignItems: "center" }}>Back</a>
                <div className="form-heading-detail pl-4">
                  {system_access_request_item.formNumber} System Access Request
                </div>
              </div>
              </div>
              <div className="col-lg-3 date-detail">
                <div className=" date-detail row mt-2">
                  Requested Date
                  <div
                    className="pl-2"
                    style={{
                      color: "#0394fc",
                    }}
                  >
                    {moment(
                      new Date(system_access_request_item.dateRequested)
                    ).format("YYYY-MM-DD")}
                  </div>
                </div>
              </div>
            </div>
            {system_access_request_item.timeTakenToComplete !== null &&
              system_access_request_item.timeTakenToComplete !== "" && (
                <div className="row ml-4 mt-1 pl-2 date">
                  <div>Time taken to complete: </div>
                  <div className="ml-2">
                    {" "}
                    {system_access_request_item.timeTakenToComplete}
                  </div>
                </div>
              )}
            <div className="horizontal-container mt-1">
              <Status progress={system_access_request_item.requestProgress} />
            </div>
          </>
        ) : (
          <CardSpinner />
        )}
      </div>

      <main>
        <div className="container-fluid">
          <form onSubmit={(e) => onAdditionalCommentPopup(e)}>
            {!request_item_loading &&
              !actionButtonLoading &&
              system_access_request_item !== null &&
              system_access_request_item.systemsSubSystems !== null && (
                <>
                  {" "}
                  <form onSubmit={(e) => onButtonClick(e)}>
                    <AdditionalComment
                      openState={open}
                      onClick={onAdditionalCommentPopup}
                      button={buttonName}
                      value={comment}
                      name="comment"
                      onChange={onChange}
                      attachmnetDisplay
                      setState={setFormData}
                                      state={formData}
                                      currentAttachments={{
                                          data:
                                              system_access_request_item.systemAccessRequesAttachments !==
                                              null &&
                                              system_access_request_item.systemAccessRequesAttachments,
                                          property: "fileName",
                                      }}
                    />
                  </form>
                  <UserInfo
                    firstName={system_access_request_item.createdUser.firstName}
                    lastName={system_access_request_item.createdUser.lastName}
                    email={system_access_request_item.createdUser.email}
                    department={
                      system_access_request_item.createdUser.department !== null
                        ? system_access_request_item.createdUser.department
                        : ""
                    }
                  />
                  <div
                    className="card-body-form px-4 py-4 mt-2 mb-4"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "5px",
                    }}
                  >
                    <div className="row">
                      {" "}
                      <div className="form-title-detail ml-3 mt-2 mb-4">
                        Employee Details
                      </div>
                    </div>
                    <div className="card-form-detail card-body">
                      <div className="row px-2 py-2">
                        <div className="col-lg-4">
                          <div className="row ml-1 mt-3 mb-2 card-title">
                            New Colleague's Emp. No.
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="employeeNumber"
                            value={system_access_request_item.employeeNumber}
                            disabled
                          />
                        </div>
                        <div className="col-lg-4">
                          <div className="row ml-1 mt-3 mb-2 card-title">
                            First Name
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="firstName"
                            value={system_access_request_item.firstName}
                            disabled
                          />
                        </div>
                        <div className="col-lg-4">
                          <div className="row ml-1 mt-3 mb-2 card-title">
                            Last Name
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            value={system_access_request_item.lastName}
                            disabled
                          />
                        </div>
                        <div className="col-lg-4">
                          <div className="row ml-1 mt-3 mb-2 card-title">
                            Employement Level
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="employmentLevel"
                            value={
                              system_access_request_item.employmentLevel !==
                              null
                                ? system_access_request_item.employmentLevel
                                    .name
                                : ""
                            }
                            disabled
                          />
                        </div>
                        <div className="col-lg-4">
                          <div className="row ml-1 mt-3 mb-2 card-title">
                            Job Title
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="jobTitle"
                            value={system_access_request_item.jobTitle}
                            disabled
                          />
                        </div>
                        <div className="col-lg-4">
                          <div className="row ml-1 mt-3 mb-2 card-title">
                            Department
                          </div>
                          <input
                            type="text"
                            className="form-control"
                            name="department"
                            value={
                              system_access_request_item.department !== null
                                ? system_access_request_item.department.name
                                : ""
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      {" "}
                      <div className="row ml-3 mt-4 mb-3">
                        <div className="form-title">
                          {" "}
                          System Access Required{" "}
                        </div>
                      </div>
                    </div>

                    <div className="card-form card-body">
                      <div className="row">
                        {!request_item_loading && form !== null && (
                          <>
                            {form.systemsSubSystems[0].fieldType == "Text"
                              ? form.systemsSubSystems.map((data, index) => (
                                  <div
                                    key={index}
                                    className="py-2 px-2 col-lg-12"
                                  >
                                    <div className="mb-3 px-4">
                                      <div className="row ml-1 mb-2 card-title">
                                        {data.name}
                                        <div
                                          className="ml-2"
                                          style={{
                                            color: "#ef233c",
                                          }}
                                        >
                                          {data.isMandatoy == true && "*"}
                                        </div>
                                      </div>
                                      <input
                                        className="form-control mt-3"
                                        type="text"
                                        maxLength={data.fieldLength}
                                        name="username"
                                        value={data.username}
                                        required={data.isMandatoy == true}
                                        disabled={data.isDisable == true}
                                        onChange={(e) =>
                                          onChangeSystem(index, e)
                                        }
                                      />
                                    </div>
                                    {data.subSystemAccess !== null &&
                                      data.subSystemAccess.map(
                                        (data, sindex) => (
                                          <div
                                            className="mb-3 px-4 ml-4"
                                            key={sindex}
                                          >
                                            <div className="row ml-1 mb-2 card-title">
                                              {data.name}
                                              <div
                                                className="ml-2"
                                                style={{
                                                  color: "#ef233c",
                                                }}
                                              >
                                                {data.isMandatoy == true && "*"}
                                              </div>
                                            </div>
                                            {data.fieldType == "Text" ? (
                                              <input
                                                className="form-control mt-3"
                                                type="text"
                                                maxLength={data.fieldLength}
                                                value={data.username}
                                                required={
                                                  data.isMandatoy == true
                                                }
                                                disabled={
                                                  data.isDisable == true
                                                }
                                                onChange={(e) =>
                                                  onChangeSubSystem(
                                                    index,
                                                    sindex,
                                                    e
                                                  )
                                                }
                                              />
                                            ) : (
                                              <NumberFormat
                                                className="form-control mt-3"
                                                value={data.username}
                                                isAllowed={withValueCap}
                                                onChange={(e) =>
                                                  onChangeSubSystem(
                                                    index,
                                                    sindex,
                                                    e
                                                  )
                                                }
                                                required={
                                                  data.isMandatoy == true
                                                }
                                                disabled={
                                                  data.isDisable == true
                                                }
                                              />
                                            )}
                                          </div>
                                        )
                                      )}
                                  </div>
                                ))
                              : form.systemsSubSystems.map((data, index) => (
                                  <div
                                    key={index}
                                    className="py-2 col-lg-4 form-check"
                                  >
                                    <div className="mb-3 px-4">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked
                                        disabled
                                      />
                                      <div
                                        className="form-check-label form-heading-checkbox"
                                        for="flexCheckDefault"
                                      >
                                        {data.name}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="row">
                          {/* <div className="form-title-detail ml-3 mt-4 mb-4">
                                Comments
                              </div> */}
                        </div>
                        <div className="card-form-detail card-body mt-4 mb-4">
                          <div className="p-2">
                            {system_access_request_item.comments != null && (
                              <CommentStaticForms
                                accessLevel={auth.access}
                                comments={system_access_request_item.comments}
                                userId={auth.id}
                                requestId={system_access_request_item.id}
                                userName={auth.firstName + " " + auth.lastName}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="row">
                          <div className="form-title-detail ml-3 mt-4 mb-4">
                            Attachments
                          </div>
                        </div>
                        <div className="card-form-detail card-body">
                          <div className="row px-2">
                            <div className="col">
                              <Link
                                className="attachment-link"
                                to="!#"
                                data-toggle="collapse"
                                data-target="#collapseAttachments"
                                aria-expanded="false"
                                aria-controls="collapseAttachments"
                              >
                                {system_access_request_item
                                  .systemAccessRequesAttachments.length > 0 ? (
                                  <div
                                    className="col-sm-6"
                                    style={{
                                      color: "#333333",
                                      fontWeight: "bold",
                                      fontFamily: "Montserrat",
                                    }}
                                  >
                                    {" "}
                                    {system_access_request_item
                                      .systemAccessRequesAttachments.length +
                                      "Attachment(s)"}
                                  </div>
                                ) : (
                                  <div>
                                    <img
                                      src={NoAttachment}
                                      alt="no_attachment"
                                      style={{
                                        height: "50px",
                                        textAlign: "center",
                                        width: "100%",
                                      }}
                                    />
                                    <div
                                      style={{
                                        textAlign: "center",
                                        color: "#333333",
                                        fontWeight: "bold",
                                        fontFamily: "Montserrat",
                                      }}
                                    >
                                      No Attachments
                                    </div>
                                  </div>
                                )}
                              </Link>
                            </div>
                            {system_access_request_item
                              .systemAccessRequesAttachments.length > 0 ? (
                              <a
                                href={`/api/SystemAccessRequest/download-attachments?requestId=${system_access_request_item.id}`}
                                target="_blank"
                                download
                                className="text-right col-sm-6"
                              >
                                <i
                                  className="fas fa-download"
                                  style={{
                                    color: "black",
                                  }}
                                ></i>
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                          <div
                            className="collapse"
                            id="collapseAttachments"
                            aria-labelledby="headingOne"
                            data-parent="#sidenavAccordion"
                          >
                            <div
                              style={{
                                height: 100,
                                overflowY: "scroll",
                              }}
                            >
                              <ul className="list-group list-group-flush">
                                {system_access_request_item.systemAccessRequesAttachments !==
                                  null &&
                                  system_access_request_item.systemAccessRequesAttachments.map(
                                    (file, index) => (
                                      <li
                                        className="list-group-item"
                                        key={index}
                                      >
                                        <div className="col-sm-11">
                                          <i className="fas fa-paperclip mr-3" />
                                          {file.fileName}
                                        </div>
                                      </li>
                                    )
                                  )}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "none" }}>
                    <Print
                      ref={componentRef}
                      requestData={system_access_request_item}
                      firstName={auth.firstName}
                      lastName={auth.lastName}
                    />
                  </div>
                  <div style={{ float: "left" }}>
                    <ReactToPrint
                      trigger={() => {
                        // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                        // to the root node of the returned component as it will be overwritten.
                        return (
                          <button
                            className="btn btn-outline-info ml-3 mb-3 w-100"
                            type="button"
                          >
                            <PrintIcon /> <b> Print This Out</b>
                          </button>
                        );
                      }}
                      content={() => componentRef.current}
                    />
                  </div>
                  <div style={{ textAlign: "right", marginRight: "15px" }}>
                    {system_access_request_item.actions !== null
                      ? system_access_request_item.actions.map(
                          (action, index) => (
                            <button
                              key={index}
                              style={{ width: "200px" }}
                              className="btn w-100 btn-primary ml-3 mb-4 col-md-2"
                              onClick={() => {
                                setButtonName(action.name);

                                onClick(
                                  action.requestProcessLevelActionId,
                                  action.requestLevelId,
                                  action.requestId,
                                  action.actionId
                                );
                              }}
                            >
                              <b>{action.name}</b>
                            </button>
                          )
                        )
                      : ""}
                  </div>
                </>
              )}
          </form>
        </div>
      </main>
    </>
  );
};

SystemAccessRequestDetail.propTypes = {
  systemAccess: PropTypes.object.isRequired,
  getSystemAccessRequestById: PropTypes.func.isRequired,
  systemAccessRequestButtonClick: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  systemAccess: state.systemAccess,
});

export default connect(mapStateToProps, {
  getSystemAccessRequestById,
  systemAccessRequestButtonClick,
})(withRouter(SystemAccessRequestDetail));

export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_SUCCESS_ADMIN = "LOGIN_SUCCESS_ADMIN";
export const LOAD_USER = "LOAD_USER";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const USER_LOADING = "USER_LOADING";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const LOGOUT = "LOGOUT";

export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const FORM_ERROR = "FORM_ERROR";
export const CLEAR_FORM = "CLEAR_FORM";
export const GET_FORM = "GET_FORM";
export const GET_FORMS = "GET_FORMS";
export const FORM_LOADING = "FORM_LOADING";

export const TILES_LOADING = "TILES_LOADING";
export const CLEAR_TILES = "CLEAR_TILES";

// Reuquest for payment form
export const GET_COST_DIVISION = "GET_COST_DIVISION";
export const SUBMIT_REQUEST_FOR_PAYMENT_ERROR =
  "SUBMIT_REQUEST_FOR_PAYMENT_ERROR";

export const CLEAR_REQUEST = "CLEAR_REQUEST";
export const CLEAR_REQUESTS = "CLEAR_REQUESTS";
export const REQUEST_FORM = "REQUEST_FORM";
export const GET_REQUEST = "GET_REQUEST";
export const GET_REQUESTS = "GET_REQUESTS";
export const GET_ALL_REQUESTS = "GET_ALL_REQUESTS";
export const REQUEST_LOADING = "REQUEST_LOADING";

export const DASHBOARD_TILE = "DASHBOARD_TILE";

export const GET_REPORTS = "GET_REPORTS";
export const SET_REPORT_LOADING = "SET_REPORT_LOADING";


export const IOU_DATA_GET_BY_ID = "IOU_DATA_GET_BY_ID";
export const PETTYCASH_DATA_GET_BY_ID = "PETTYCASH_DATA_GET_BY_ID";

// Settlement Types
export const SETTLEMENT_IOU_LOADING = "SETTLEMENT_IOU_LOADING";
export const SETTLEMENT_PETTYCASH_LOADING = "SETTLEMENT_PETTYCASH_LOADING";
export const SETTLEMENT_CASHBOX_LOADING = "SETTLEMENT_CASHBOX_LOADING";
export const SETTLEMENT_LOADING = "SETTLEMENT_LOADING";

export const GET_REMAINING_CASHBOX = "GET_REMAINING_CASHBOX";
export const GET_PENDING_SETTLEMENT_IOU = "GET_PENDING_SETTLEMENT_IOU";
export const GET_PENDING_SETTLEMENT_PETTYCASH =
  "GET_PENDING_SETTLEMENT_PETTYCASH";

export const GET_SETTLEMENT_LOG = "GET_SETTLEMENT_LOG";

export const CLEAR_SETTLEMENT_PETTY_CASH = "CLEAR_SETTLEMENT_PETTY_CASH";
export const CLEAR_SETTLEMENT_IOU = "CLEAR_SETTLEMENT_IOU";

// End of Settlement Types

export const GET_CASHBOX_DATA = "GET_CASHBOX_DATA";
export const GET_CASHBOX_HISTORY_DATA = "GET_CASHBOX_HISTORY_DATA";
export const REMAIN_CASH_LOADING = "REMAIN_CASH_LOADING";
export const REQUEST_HISTORY_LOADING = "REQUEST_HISTORY_LOADING";

export const GET_REIMBURSE_DATA = "GET_REIMBURSE_DATA";
export const GET_REIMBURSE_BY_ID = "GET_REIMBURSE_BY_ID";
export const SET_REIMBURSE_LOADING = "SET_REIMBURSE_LOADING";

export const GET_ALL_SYSTEM_ACCESSES = "GET_ALL_SYSTEM_ACCESSES";
export const GET_ALL_EMP_LEVEL = "GET_ALL_EMP_LEVEL";
export const GET_ALL_EMP_DEPARTMENTS = "GET_ALL_EMP_DEPARTMENTS";
export const GET_SYSTEM_ACCESS_REQUEST_BY_ID =
  "GET_SYSTEM_ACCESS_REQUEST_BY_ID";

// POS Request Region
export const GET_ALL_OUTLETS = "GET_ALL_OUTLETS";
export const GET_ALL_MENU_OPTIONS = "GET_ALL_MENU_OPTIONS";
export const GET_ALL_CATEGORIES = "GET_ALL_CATEGORIES";
export const GET_ALL_PRICE_UPDATES = "GET_ALL_PRICE_UPDATES";
// End POS Request

export const GET_POS_ITEM_REQUEST_BY_ID = "GET_POS_ITEM_REQUEST_BY_ID";

export const GET_NAV_LINKS = "GET_NAV_LINKS";
export const SIDEBAR_SET = "SIDEBAR_SET";
export const ACCESS_LEVEL_SET = "ACCESS_LEVEL_SET";
export const ACCESS_PEETYCASH_LEVEL_SET = "ACCESS_PEETYCASH_LEVEL_SET";
export const ACCESS_PEETYCASH_CLEAR = "ACCESS_PEETYCASH_CLEAR";


// Begining user management
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USER_ROLES = "GET_ALL_USER_ROLES";
export const GET_ALL_EMP_LEVELS = "GET_ALL_EMP_LEVELS";
export const GET_BRANCH_DATA = "GET_BRANCH_DATA";
export const USER_PROFILE_LOADING = "USER_PROFILE_LOADING";
export const USER_MANAGEMENT_GET_USER_BY_ID = "USER_MANAGEMENT_GET_USER_BY_ID";
export const CLEAR_USER_DETAIL = "CLEAR_USER_DETAIL";
export const CHANGE_MY_PASSWORD = "CHANGE_MY_PASSWORD";
//End user management

// Begining role management
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const GET_ROLE_BY_ID = "GET_ROLE_BY_ID";
export const GET_ALL_ACCESS_LEVELS = "GET_ALL_ACCESS_LEVELS";
export const ROLE_LOADING = "ROLE_LOADING";
// End role management

// Start of Department Management
export const GET_SUGGEST_DEPARTMENT = "GET_SUGGEST_DEPARTMENT";
export const GET_ALL_DEPARTMENT_HEADS = "GET_ALL_DEPARTMENT_HEADS";
export const GET_DEPARTMENT_BY_ID = "GET_DEPARTMENT_BY_ID";
export const DEPARTMENT_LOADING = "DEPARTMENT_LOADING";
export const GET_ALL_DEPARTMENT_RELATIONSIP_MANAGERS =
    "GET_ALL_DEPARTMENT_RELATIONSIP_MANAGERS";
// End of Department Management

//Start of Division
export const GET_ALL_DIVISIONS = "GET_ALL_DIVISIONS";
export const GET_DIVISION_BY_ID = "GET_DIVISION_BY_ID";
export const GET_ALL_DIVISION_HEADS = "GET_ALL_DIVISION_HEADS";
//End of Division


//temporary credit request begin
export const GET_ALL_RELATIONSHIP_MANAGERS_FOR_DEPARTMENT =
    "GET_ALL_RELATIONSHIP_MANAGERS_FOR_DEPARTMENT";
export const GET_RELATIONSHIP_MANAGER_FOR_USER =
    "GET_RELATIONSHIP_MANAGER_FOR_USER";
export const GET_TEMP_CREDIT_REQUEST_BY_ID = "GET_TEMP_CREDIT_REQUEST_BY_ID";
export const GET_CURRENCY_VALUES = "GET_CURRENCY_VALUES";
export const GET_ALL_CREDIT_TERMS = "GET_ALL_CREDIT_TERMS";
export const CLEAR_REQUEST_ITEM = "CLEAR_REQUEST_ITEM";
//temporary credit request END

//start of advance payment
export const GET_ALL_CURRENCY = "GET_ALL_CURRENCY";
export const GET_SUPPLIER_ADVANCE_REQ_BY_ID = "GET_SUPPLIER_ADVANCE_REQ_BY_ID";
export const CLEAR_ADVANCE_PAYMENT_REQUEST = "CLEAR_ADVANCE_PAYMENT_REQUEST";

//end of advance payment
//temporary credit request END


//Credit Evaluation Begin
export const GET_CREDIT_EVALUATION_REQUEST_BY_ID =
    "GET_CREDIT_EVALUATION_REQUEST_BY_ID";
export const CLEAR_CREDIT_REQUEST = "CLEAR_CREDIT_REQUEST";
//Credit Evaluation End
//temporary credit request END



// Request for refund Begin
export const GET_ALL_RECOVERY_TYPES = "GET_ALL_RECOVERY_TYPES";
export const GET_ALL_REASONS_FOR_REFUND = "GET_ALL_REASONS_FOR_REFUND";
export const GET_REQUEST_FOR_REFUND_BY_ID = "GET_REQUEST_FOR_REFUND_BY_ID";
export const GET_CURRENCY_VALUES_FOR_REQUEST_FOR_REFUND =
    "GET_CURRENCY_VALUES_FOR_REQUEST_FOR_REFUND";
// Request for refund end

export const GET_APPROVED_REQUESTS_COUNT = "GET_APPROVED_REQUESTS_COUNT";
export const GET_PENDING_REQUESTS_COUNT = "GET_PENDING_REQUESTS_COUNT";
export const GET_REJECTED_REQUESTS_COUNT = "GET_REJECTED_REQUESTS_COUNT";
import React, { useEffect, useState } from "react";
import { getRequests, setRequestLoading } from "../../actions/requests";
import {
    getApprovedRequests,
    getRejectedRequests,
    getPendingRequests,
    getAllRequests,
} from "../../actions/dashboard";
import { withStyles, makeStyles, useTheme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import Spinner from "../layout/Spinner";
import DashboardCard from "../Card/DashboardCard";

const UserDashboard = ({
    getRequests,
    getAllRequests,
    getApprovedRequests,
    getRejectedRequests,
    getPendingRequests,
    setRequestLoading,
    auth: { isAuthenticated, id, loading },
    requests: { requests, requests_loading },
    history,
}) => {
    useEffect(() => {
        if (!loading) {
            getRequests(id);
        }
    }, [getRequests]);

    const [requestData, setRequestData] = useState([]);

    useEffect(() => {
        if (requests !== null) {
            setRequestData(requests);
        }
    }, [requests]);

    const [searchText, setSearchText] = useState("");

    const [dropdownState, setDropdownState] = useState("Pending");

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        if (searchValue !== "" && requests !== null) {
            let filtered = requests.filter((item) => {
                return (
                    item.formNumber.includes(searchValue) ||
                    item.name.toLowerCase().includes(searchValue.toLowerCase())
                );
            });
            setRequestData(filtered);
        } else {
            setRequestData(requests);
        }
    };
   
    const onChange = (e) => {
        if (e.target.value === "Approved") {
            setRequestLoading();
            getApprovedRequests(id);
        }
        if (e.target.value === "Rejected") {
            setRequestLoading();
            getRejectedRequests(id);
        }
        if (e.target.value === "Pending") {
            setRequestLoading();
            getPendingRequests(id);
        }
        if (e.target.value === "All") {
            setRequestLoading();
            getAllRequests(id);
        }
    };

    // Defualt Material UI Package functions
    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: "#dadee7",
            color: theme.palette.common.black,

            paddingTop: "13px",
            paddingBottom: "13px",
        },
        body: {
            fontSize: 15,
        },
    }))(TableCell);

    const useStyles1 = makeStyles((theme) => ({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }));

    // Defualt Material UI Package functions
    const StyledTableRow = withStyles((theme) => ({
        root: {
            "&:nth-of-type(odd)": {
                backgroundColor: theme.palette.action.hover,
            },
        },
    }))(TableRow);

    // @Style General
    const useStyles = makeStyles((theme) => ({
        table: {
            minWidth: "100%",
        },

        tr: {
            background: "#fff",
            "&:hover": {
                background: "rgba(51,51,51,0.2)",
            },
        },
        formControl: {
            margin: theme.spacing(1),

            minWidth: "30%",
            maxWidth: "100%",
        },
        select: { fontSize: "20px" },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
        search: {
            position: "relative",
            borderRadius: 28,
            boxShadow: "0 5px 10px rgba(0, 0, 0, 0), 0 5px 5px rgba(0, 0, 0, 0.23)",
            backgroundColor: "#fafafa",
            "&:hover": {
                backgroundColor: "#fafafa",
            },
            marginRight: theme.spacing(2),
            marginLeft: 0,
            marginTop: 0,
            width: "100%",
            height: "55px",
        },
        searchIcon: {
            padding: theme.spacing(0, 2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        inputRoot: {
            color: "inherit",
        },
        inputInput: {
            marginTop: 10,
            padding: theme.spacing(1, 1, 1, 0),
            // vertical padding + font size from searchIcon
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
        },
    }));

    // @Function for hadle pagination
    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;

        const handleFirstPageButtonClick = (event) => {
            onChangePage(event, 0);
        };

        const handleBackButtonClick = (event) => {
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onChangePage(event, page + 1);
        };

        const handleLastPageButtonClick = (event) => {
            onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
        };

        return (
            !loading && (
                <div className={classes.root}>
                    <IconButton
                        onClick={handleFirstPageButtonClick}
                        disabled={page === 0}
                        aria-label="first page"
                    >
                        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
                    </IconButton>
                    <IconButton
                        onClick={handleBackButtonClick}
                        disabled={page === 0}
                        aria-label="previous page"
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleNextButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="next page"
                    >
                        {theme.direction === "rtl" ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </IconButton>
                    <IconButton
                        onClick={handleLastPageButtonClick}
                        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                        aria-label="last page"
                    >
                        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
                    </IconButton>
                </div>
            )
        );
    }

    TablePaginationActions.propTypes = {
        count: PropTypes.number.isRequired,
        onChangePage: PropTypes.func.isRequired,
        page: PropTypes.number.isRequired,
        rowsPerPage: PropTypes.number.isRequired,
    };

    // @Method onClick for redirect to form id
    const onClick = (id, status, requestStatus, open="current") => {
        if (status === "Request For Payment" && requestStatus === "Draft") {
            if (open === "current") {
                history.push(
                    `/user-dashboard/request/static/request-for-payment/edit/${id}`
                );
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/request/static/request-for-payment/edit/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "Request For Payment") {
            if (open === "current") {
                history.push(
                    `/user-dashboard/request/static/request-for-payment/view/${id}`
                );
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/request/static/request-for-payment/view/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "IOU") {
            if (open === "current") {
                history.push(`/user-dashboard/request/pettycash/request-iou/${id}`);
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/request/pettycash/request-iou/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "Petty Cash") {
            if (open === "current") {
                history.push(
                    `/user-dashboard/request/pettycash/request-pettycash/${id}`
                );
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/request/pettycash/request-pettycash/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "Petty Cash Reimbursement") {
            if (open === "current") {
                history.push(`/user-dashboard/petty-cash/reimburse/${id}`);
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/petty-cash/reimburse/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "System Access Request") {
            if (open === "current") {
                history.push(`/user-dashboard/system-access-request-detail/${id}`);
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/system-access-request-detail/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "POS Item Request") {
            if (open === "current") {
                history.push(`/user-dashboard/pos-request-detail/${id}`);
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/pos-request-detail/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "Temporary Credit Request") {
            if (open === "current") {
                history.push(`/user-dashboard/temporary-credit-request-detail/${id}`);
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/temporary-credit-request-detail/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "Request For Refund") {
            if (open === "current") {
                history.push(`/user-dashboard/request-for-refund-detail/${id}`);
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/request-for-refund-detail/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "Credit Evaluation Request") {
            if (open === "current") {
                history.push(`/user-dashboard/credit-evaluation-detail/${id}`);
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/credit-evaluation-detail/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else if (status === "Advance Payment Request") {
            if (open === "current") {
                history.push(`/user-dashboard/supplier-advance-payment-detail/${id}`);
            } else if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/supplier-advance-payment-detail/${id}`,
                    "_blank"
                );
                win.focus();
            }
        } else {
            if (open === "newTab") {
                var win = window.open(
                    `/user-dashboard/request/${id}`,
                    "_blank"
                );
                win.focus();
            } else {
              history.push(`/user-dashboard/request/${id}`);
            }
        }
    };

    //@State for pagination
    const classes = useStyles();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const emptyRows =
        rowsPerPage -
        Math.min(rowsPerPage, requestData.length - page * rowsPerPage);

    // @Method HandleChangePage
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    // @Method Hadle RowsperPage
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    if (!isAuthenticated) {
        return <Redirect to="/" />;
    }

    return (
        <div className="px-4 py-2">
            <div className="mt-3 mb-3">
                <DashboardCard setState={(e) => setDropdownState(e)} />

                <div className="card card-body px-3">
                    <div className="row mb-4">
                        <div className="col-md-7 w-100 mt-3">
                            <FormControl className={classes.formControl}>
                                <Select
                                    className={classes.select}
                                    value={dropdownState}
                                    id="progressStatus"
                                    onChange={(e) => onChange(e)}
                                    inputProps={{ "aria-label": "Without label" }}
                                >
                                    <MenuItem value="All" onClick={() => setDropdownState("All")}>
                                        My Request
                                    </MenuItem>
                                    <MenuItem
                                        value="Approved"
                                        onClick={() => setDropdownState("Approved")}
                                    >
                                        Approved
                                    </MenuItem>
                                    <MenuItem
                                        value="Pending"
                                        onClick={() => setDropdownState("Pending")}
                                    >
                                        Pending
                                    </MenuItem>
                                    <MenuItem
                                        value="Rejected"
                                        onClick={() => setDropdownState("Rejected")}
                                    >
                                        Rejected
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div className=" col-md-5 mt-3 mb-3" style={{ float: "right" }}>
                            <div>
                                <div className={classes.grow}>
                                    <div className={classes.search}>
                                        <div className={classes.searchIcon}>
                                            <SearchIcon />
                                        </div>
                                        <InputBase
                                            placeholder="Search…"
                                            classes={{
                                                root: classes.inputRoot,
                                                input: classes.inputInput,
                                            }}
                                            inputProps={{ "aria-label": "search" }}
                                            value={searchText}
                                            onChange={(e) => requestSearch(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {!requests_loading ? (
                        <div className="grid-container">
                            <TableContainer component={Paper}>
                                <Table
                                    className={classes.table}
                                    aria-label="customized pagination table"
                                >
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell align="left">
                                                Form Number
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Created Date
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Created User
                                            </StyledTableCell>
                                            <StyledTableCell align="left">
                                                Request Type
                                            </StyledTableCell>
                                            <StyledTableCell align="left">Status</StyledTableCell>
                                            <StyledTableCell align="left"></StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {requestData.length > 0
                                            ? (rowsPerPage > 0
                                                ? requestData.slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                : requestData
                                            ).map((request, id) => (
                                                <StyledTableRow
                                                    key={id}
                                                    tabIndex={-1}
                                                    className={classes.tr}
                                                    onClick={() =>
                                                        onClick(request.id, request.name, request.status)
                                                    }
                                                >
                                                    <StyledTableCell component="th" scope="row">
                                                        {request.formNumber}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {request.createdOn}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {request.createdBy}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {request.name}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {request.status}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        <svg
                                                            onClick={(e) => {
                                                                e.stopPropagation()
                                                                onClick(
                                                                    request.id,
                                                                    request.name,
                                                                    request.status,
                                                                    "newTab"
                                                                )
                                                            }
                                                            }
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="blue"
                                                            class="bi bi-box-arrow-up-right"
                                                            viewBox="0 0 16 16"
                                                            cursor="pointer"
                                                        >
                                                            <path
                                                                fill-rule="evenodd"
                                                                d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"
                                                            />
                                                            <path
                                                                fill-rule="evenodd"
                                                                d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"
                                                            />
                                                        </svg>
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))
                                            : ""}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[5, 10, 25, 100]}
                                                colSpan={5}
                                                count={requestData.length}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { "aria-label": "rows per page" },
                                                    native: true,
                                                }}
                                                onChangePage={handleChangePage}
                                                onChangeRowsPerPage={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </div>
                    ) : (
                        <Spinner />
                    )}
                </div>
            </div>
        </div>
    );
};

UserDashboard.propTypes = {
    auth: PropTypes.object.isRequired,
    getRequests: PropTypes.func.isRequired,
    getApprovedRequests: PropTypes.func.isRequired,
    getRejectedRequests: PropTypes.func.isRequired,
    getPendingRequests: PropTypes.func.isRequired,
    setRequestLoading: PropTypes.func.isRequired,
    requests: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    dashboard: state.dashboard,
    requests: state.requests,
});

export default connect(mapStateToProps, {
    getRequests,
    getAllRequests,
    getApprovedRequests,
    getRejectedRequests,
    getPendingRequests,
    setRequestLoading,
})(UserDashboard);

import {
    GET_ALL_SYSTEM_ACCESSES,
    GET_ALL_EMP_LEVEL,
    GET_ALL_EMP_DEPARTMENTS,
    GET_SYSTEM_ACCESS_REQUEST_BY_ID,
} from "../actions/types";

const initialState = {
    system_access: [],
    loading: true,
    emp_level: [],
    empLevel_loading: true,
    emp_departments: [],
    departments_loading: true,
    system_access_request_item: null,
    request_item_loading: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SYSTEM_ACCESSES:
            return {
                ...state,
                loading: false,
                system_access: action.payload,
            };
        case GET_ALL_EMP_LEVEL:
            return {
                ...state,
                empLevel_loading: false,
                emp_level: action.payload,
            };
        case GET_ALL_EMP_DEPARTMENTS:
            return {
                ...state,
                departments_loading: false,
                emp_departments: action.payload,
            };
        case GET_SYSTEM_ACCESS_REQUEST_BY_ID:
            return {
                ...state,
                request_item_loading: false,
                system_access_request_item: action.payload,
            };

        default:
            return state;
    }
};

export default reducer;

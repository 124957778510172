import { combineReducers } from "redux";
import auth from "./authReducers";
import forms from "./formsReducer";
import requests from "./requestReducer";
import alert from "./alertReducer";
import dashboard from "./dashboardReducer";
import reports from "./reportsReducers";
import IOU from "./IOUReducer";
import pettyCash from "./pettyCashReducer";
import settlement from "./settlementReducer";
import cashbox from "./cashboxReducer";
import reimburse from "./reimburseReducer";
import systemAccess from "./systemAccessReducer";
import pos from "./posRequestReducer";
import app from "./appReducer";
import user from "./userManagementReducer";
import role from "./roleManagementReducer";
import department from "./departmentManagementReducer";
import division from "./divisionManagementReducer";
import tempCreditReq from "./tempCreditReqReducer";
import requestForRefund from "./requestForRefundReducer";
import creditEvaluation from "./creditEvaluationReducer";
import supplierAdvancePaymentReq from "./supplierAdvancePaymentReq";

export default combineReducers({
  auth,
  forms,
  requests,
  alert,
  dashboard,
  reports,
  IOU,
  pettyCash,
  settlement,
  cashbox,
  reimburse,
  systemAccess,
  pos,
  app,
  user,
  role,
  department,
  division,
  tempCreditReq,
requestForRefund,
creditEvaluation,
supplierAdvancePaymentReq
});

import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Sidebar from "../../sidebar/Sidebar";
import { connect } from "react-redux";
import { getCashboxData } from "../../../actions/cashbox";
import { submitCashbox } from "../../../actions/cashbox";
import { getCashboxHistory } from "../../../actions/cashbox";
import Spinner from "../../layout/Spinner";
import "./index.css";
import DateRangeCalender from "../Shared/DateRangeCalender";
import Grid from "../Shared/Grid";
import Button from "@material-ui/core/Button";
import NumberFormat from "react-number-format";
var moment = require("moment");

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    margin: {
        margin: theme.spacing(1),
    },
    withoutLabel: {
        marginTop: theme.spacing(3),
    },
    textField: {
        width: "25ch",
    },
}));

const Cashbox = ({
    getCashboxData,
    getCashboxHistory,
    submitCashbox,
    cashbox: { cashbox, loading, cashbox_history, history_loading },
}) => {
    const classes = useStyles();

    const styles = (theme) => ({
        textField: {
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingBottom: 0,
            marginTop: 0,
            fontWeight: 500,
        },
        input: {
            color: "white",
        },
    });

    const headerCells = [
        {
            id: "remainingCash",
            numeric: false,
            disablePadding: false,
            name: "Remaining Cash (LKR)",
            propertyValue: "remainingCash",
            nested: false,
            type: "numeric",
        },
        {
            id: "cashAdded",
            numeric: false,
            disablePadding: false,
            name: "Reimburse Amount (LKR)",
            propertyValue: "cashAdded",
            nested: false,
            type: "numeric",
        },
        {
            id: "name",
            numeric: false,
            disablePadding: false,
            name: "Name",
            propertyValue: "createdUser",
            nested: true,
            nestedValue: ["firstName", "lastName"],
            type: "text",
        },
        {
            id: "email",
            numeric: false,
            disablePadding: false,
            name: "Email",
            propertyValue: "createdUser",
            nested: true,
            nestedValue: ["email"],
            type: "text",
        },
        {
            id: "date",
            numeric: false,
            disablePadding: false,
            name: "Date",
            propertyValue: "createdOn",
            nested: false,
            type: "date",
        },
    ];

    // Calculate three months prior from today
    var threeMonthsAgo = moment().subtract(3, "months");

    //variable for calculate total cash
    var totalCash = "";

    //state for display total cash
    var [total, setTotal] = useState("0.00");

    //state for submit form data
    const [formData, setFormData] = useState({
        maxCash: "",
        remainingCash: "",
        cashAdded: "",
    });

    const { maxCash, remainingCash, cashAdded } = formData;

    // State for Date submission
    const [dateRange, setDateRange] = useState({
        from: threeMonthsAgo.format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD"),
    });

    const { from, to } = dateRange;

    useEffect(() => {
        if (from !== "" && to !== "") {
            getCashboxData(dateRange);
        }
    }, [dateRange]);

    const onchange = (e) => {
        e.preventDefault();
        setFormData({
            ...FormData,
            [e.target.name]: e.target.value,
        });

        if (e.target.value !== "") {
            setTotal(parseFloat(cashbox.remainingCash) + parseFloat(e.target.value));
        } else setTotal("0.00");
    };

    //&& parseFloat(cashbox.remainingCash) + parseFloat(formData.cashAdded) > 0
    const onSubmit = (e) => {
        if (
            parseFloat(cashbox.remainingCash) + parseFloat(formData.cashAdded) <=
            parseFloat(cashbox.maxCash)
        ) {
            if (parseFloat(formData.cashAdded) > 0) {
                e.preventDefault();
                totalCash = parseFloat(totalCash);
                cashbox.remainingCash = parseFloat(cashbox.remainingCash);
                formData.cashAdded = parseFloat(formData.cashAdded);
                totalCash = cashbox.remainingCash + formData.cashAdded;
                formData.remainingCash = totalCash;
                formData.maxCash = cashbox.maxCash;
                setTotal(totalCash);
               
                submitCashbox(formData, dateRange);
                formData.cashAdded = "";
                setTotal("0.00");
            } else {
                e.preventDefault();
                formData.cashAdded = "";
                //console.log("0 or minus");
            }
        } else {
            e.preventDefault();
            formData.cashAdded = "";
            //console.log("overlimit");
        }
    };

    return (
        <main>
            <div
                className="card w-100 sticky-top mb-3 p-3 detail-sticky"
                style={{
                    maxHeight: "100%",
                    zIndex: "1035",
                    paddingTop: "1em",
                }}
            >
                <div className="row">
                    <div className="col-md-10">
                        <div
                            style={{
                                color: "#333333",
                                fontWeight: "bold",
                                fontSize: "25px",

                                fontFamily: "Montserrat",
                            }}
                        >
                            Cash Box
                        </div>
                    </div>

                    {/*{!loading && cashbox !== null ? (*/}
                    {/*<div className="col-md-2" >*/}
                    {/*    <div className="small-heading-cashbox">Max Cash Limit</div>*/}
                    {/*    <div className="amount-heading-cashbox-maxCash">*/}
                    {/*        <span className="amount-heading-lkr">*/}
                    {/*            LKR{' '}*/}
                    {/*        </span>*/}
                    {/*        {cashbox.maxCash}*/}
                    {/*    </div>*/}
                    {/*    </div>*/}
                    {/*) : (<div className="mt-5">*/}
                    {/*    <Spinner />*/}
                    {/*</div>)}*/}
                </div>
            </div>

            <div className="pl-4 pr-4 py-4">
                <div className="card card-body p-3">
                    {!loading && cashbox !== null ? (
                        <form onSubmit={(e) => onSubmit(e)}>
                            <div className="row">
                                <div className="col-md-4 p-3">
                                    <div className="small-heading-cashbox">Max Cash Limit</div>
                                    <span className="amount-heading-lkr">LKR </span>
                                    <NumberFormat
                                        className="amount-heading-cashbox-maxCash"
                                        value={cashbox.maxCash}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                    ></NumberFormat>
                                </div>

                                <div className="col-md-4 p-3">
                                    <div className="small-heading-cashbox">Remaining Cash</div>
                                    <span
                                        className="amount-heading-lkr"
                                        style={{ color: "#343a41" }}
                                    >
                                        LKR{" "}
                                    </span>

                                    <NumberFormat
                                        className="amount-heading-cashbox-normal"
                                        value={cashbox.remainingCash}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        fixedDecimalScale={true}
                                        decimalScale={2}
                                    ></NumberFormat>
                                    {/*<div className="amount-heading-cashbox-normal">*/}

                                    {/*    {cashbox.remainingCash}*/}

                                    {/*</div>*/}
                                </div>

                                {/*<div className="col-md-4 p-3">*/}
                                {/*<div className="small-heading-cashbox cashbox-input">Add Cash (LKR)</div>*/}
                                {/*<input type="number"*/}
                                {/*    className="form-control cashbox-input"*/}
                                {/*    name="cashAdded"*/}
                                {/*    value={cashAdded}*/}
                                {/*     onChange={(e) => onchange(e)}></input>*/}

                                {/*<div className={classes.root}>*/}
                                {/*    <div>*/}

                                {/*        <TextField*/}
                                {/*            type="number"*/}
                                {/*            label="Add Cash"*/}
                                {/*            id="standard-start-adornment"                                                */}
                                {/*            className={clsx(classes.margin, classes.textField)}*/}
                                {/*            name="cashAdded"*/}
                                {/*            value={cashAdded}*/}
                                {/*            //InputLabelProps={{ style: { fontSize: 18, fontWeight:1000 } }}*/}
                                {/*            onChange={(e) => onchange(e)}*/}
                                {/*            InputProps={{*/}

                                {/*                   startAdornment: <InputAdornment position="start">LKR</InputAdornment>,*/}
                                {/*                 }}*/}
                                {/*               />*/}
                                {/*    </div>*/}
                                {/*</div>*/}

                                {/*</div>*/}

                                {/*<div className="col-md-3 p-3">*/}
                                {/*    <div className="small-heading-cashbox">Total Cash</div>*/}
                                {/*    <div className="amount-heading-cashbox-normal">*/}
                                {/*        <span className="amount-heading-lkr" style={{ color: '#343a41' }}>*/}
                                {/*            LKR{' '}*/}
                                {/*        </span>*/}

                                {/*        {total}*/}
                                {/*    </div>*/}
                                {/* </div>*/}

                                {/*<div className="col-md-2 p-3 btn-add-cash">*/}

                                {/*<button*/}

                                {/*    type="submit"*/}
                                {/*    className="btn btn-primary btn-add-cash btn-md"*/}
                                {/*>*/}
                                {/*    Add*/}
                                {/*</button>*/}

                                {/* <Button variant="contained" type="submit" color="primary"*/}
                                {/*     className="btn-add-cash"*/}
                                {/*     style={{*/}

                                {/*         backgroundColor: "#24a9b5",*/}

                                {/*     }}>*/}
                                {/*     Add*/}
                                {/*</Button>*/}

                                {/*</div>*/}
                            </div>
                        </form>
                    ) : (
                        <div>
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>

            <div className="pl-4 pr-4 py-2 mb-3">
                <div className="card card-body p-3">
                    <div className="px-3">
                        <div className="header-with-date-container">
                            <div className="mb-3">
                                <h2
                                    style={{
                                        fontSize: "25px",
                                        border: "none",
                                        fontFamily: "Arial",
                                        fontWeight: "bold",
                                        color: "gray",
                                    }}
                                >
                                    Previous
                                </h2>
                            </div>
                            <div className="mb-3">
                                <DateRangeCalender
                                    setDate={setDateRange}
                                    initialState={dateRange}
                                    range_picker={true}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-12 mb-3 py-3">
                        <Fragment>
                            <div>
                                <Grid
                                    headers={headerCells}
                                    data={cashbox_history !== null ? cashbox_history : []}
                                />
                            </div>
                        </Fragment>
                    </div>
                </div>
            </div>
        </main>
    );
};

Cashbox.propTypes = {
    cashbox: PropTypes.object.isRequired,
    getCashboxData: PropTypes.func.isRequired,
    submitCashbox: PropTypes.func.isRequired,
    getCashboxHistory: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    cashbox: state.cashbox,
});

export default connect(mapStateToProps, {
    getCashboxData,
    getCashboxHistory,
    submitCashbox,
})(Cashbox);
